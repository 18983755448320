import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const GatsbyImage = ({ img, className }) =>
  img && (
    <Img
      className={className}
      fluid={img.imageFile.childImageSharp.fluid}
      alt={img.altText}
    />
  )

const GatsbySrcImage = ({ img, className, altText }) =>
  img && (
    <Img
      className={className}
      fluid={img.childImageSharp.fluid}
      alt={altText}
    />
  )

const NonStretchImg = props => {
  let normalizedProps = props
  if (
    props.fluid &&
    props.fluid.presentationWidth &&
    props.fluid.presentationHeight
  ) {
    normalizedProps = {
      ...props,
      fluid: props.img.childImageSharp.fluid,
      alt: props.img.altText,
      style: {
        maxWidth: props.fluid.presentationWidth,
        maxHeight: props.fluid.presentationHeight,
        width: "100%",
        height: "100%",
        ...(props.style || {}),
      },
    }
  }

  return <Img {...normalizedProps} />
}

export { GatsbyImage, GatsbySrcImage, NonStretchImg }

export const query = graphql`
  fragment GatsbyImageQuery on WPGraphQL_MediaItem {
    altText
    sourceUrl
    imageFile {
      childImageSharp {
        fixed(width: 246, height: 260) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1920, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
